import { ICaseItemDtoV2, IInventoryItemDetailed, IInventoryItemDetailedReq, IMarketplaceKitData } from '@dev-fast/types';

export interface SkinsStateModel {
  itemDetailedMeta: IInventoryItemDetailedReq | null;
  itemAvailabilityInCases: ICaseItemDtoV2[] | null;
  itemDetailsRequestState: boolean | null;
  itemDetails: IInventoryItemDetailed | null;
  itemAvailabilityOnThenMarket: IMarketplaceKitData[] | null;
  direction: string;
}
export const SKINS_INITIAL_STATE: SkinsStateModel = {
  itemDetailedMeta: null,
  itemAvailabilityInCases: null,
  itemDetailsRequestState: null,
  itemAvailabilityOnThenMarket: null,
  itemDetails: null,
  direction: '',
};
